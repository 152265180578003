<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <v-tabs
                        background-color="indigo darken-4 hidden-sm-and-down main-sub-menu"
                        dark
                        centered
                >
                    <v-tab  to="/about/intro">
                        인사말
                    </v-tab>
                    <v-tab  to="/about/roadMap" class="active">
                        오시는 길
                    </v-tab>
                    <v-tab  to="/userGuide/near">
                        주변관광지
                    </v-tab>
                </v-tabs>
                <v-row class="main-title-img-2" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            오시는 길
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="3" class="mb-5">
                            <h1 class="about-sub-title">오시는 길</h1>
                        </v-col>
                        <v-col cols="12" md="9">
                            <div id="map" style="width:100%;height:480px;"></div>
                        </v-col>
                        <v-col cols="12" md="3"></v-col>
                        <v-col cols="12" md="9">
                            <v-card
                                    class="mx-auto"
                            >
                                <v-card-text>
                                    <div class="text--primary">
                                        주소 : 전라북도 완주군 운주면 고당리 493 / (553) 번지(1 km직진)
                                    </div>
                                    <div class="text--primary">
                                        TEL : 010-3955-0677
                                    </div>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                            text
                                            color="deep-purple accent-4"
                                            @click="getNaverMap"
                                    >
                                        길찾기
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>


                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>


    export default {
        name: 'Near',

        components: {},
        data: () => ({


        }),
        created: function(){
            // 맨위로
            window.scrollTo(0,0);
        },
        mounted() {
            this.$nextTick(() => {
                var mapOptions = {
                    center: new naver.maps.LatLng(36.01830091015144, 127.35030939019462),
                    zoom: 18
                };
                /*36.01836533947182, 127.34992248215671*/
                var map = new naver.maps.Map('map', mapOptions);

                var marker = new naver.maps.Marker({
                    position: new naver.maps.LatLng(36.01830091015144, 127.35030939019462),
                    map: map
                });



            })
        },
        methods: {
            getNaverMap(){
                var ret = window.open('https://map.naver.com/v5/directions/-/14176572.474197228,4303140.0601490475,%EA%B3%84%EA%B3%A1%EC%9D%BC%EB%B2%88%EC%A7%80,1229054824,PLACE_POI/-/transit?c=14176572.4741978,4303140.0601491,18,0,0,0,dh', '_blank');

            }
        }
    }
</script>

<style scoped>

</style>

